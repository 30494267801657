import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_ARTICLE_WITNESS,
  GET_ARTICLE_WITNESSES,
  STORE_ARTICLE_WITNESS,
  UPDATE_ARTICLE_WITNESS
} from "@/store/modules/article.witness.module";
export  default {
  methods: {
    storeArticleWitness(article_id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_ARTICLE_WITNESS, { article_id, formData }), actions)
    },
    getArticleWitness(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_WITNESS, id), actions)
    },
    updateArticleWitness(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_ARTICLE_WITNESS, { id, formData }), actions)
    },
    getArticleWitnesses(article_id, page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_WITNESSES, { article_id, page }), actions)
    }
  }
}
