<template>
  <b-overlay :show="loading">
    <div class="home-container">
      <pagination :implementSearch="false" :meta="articleWitnessesMeta" @refresh="onRefresh" @change-page="onChangePage" />
      <b-row>
        <b-col sm="6" md="3" class="d-flex" v-for="witness in articleWitnesses" :key="witness.id">
          <portlet title=" " body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm" foot-class="mt-auto">
            <template v-slot:body>
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar rounded size="50px" text="!" :src="witness.user && witness.user.avatar" />
                    </div>
                    <div class="kt-widget__info">
                      <a class="kt-widget__username">
                        {{ witness.full_name }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__label">{{ $t("PAGES.ARTICLE_WITNESS.HOME.LABEL.phone") }}</span>
                        <span class="kt-widget__data">{{ witness.phone }}</span>
                      </div>
                      <div class="kt-widget__contact cursor-default">
                        <span class="kt-widget__data text-justify">{{ witness.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot>
              <b-button-group>
                <b-button disabled block variant="primary" :pressed="false" :to="{name: `article.witnesses.edit`, params: {id: witness.id}}">{{ $t("COMMON.FORM.BUTTONS.EDIT") }}</b-button>
                <b-button variant="danger" :pressed="false" @click="onChangeSwitch(witness.id, 'audit', witness.audit)"><span class="fa " :class="[witness.audit ? 'fa-eye' : 'fa-eye-slash']"></span></b-button>
              </b-button-group>
            </template>
          </portlet>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import Portlet from "@v@/partials/content/Portlet";
  import pagination from "@v@/components/paginate";
  import {mapGetters} from 'vuex';
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import witnessMixin from "@m@/article.witness.mixin";
  import {SET_ARTICLE_WITNESS_ERROR, SET_ARTICLE_WITNESSES} from "@/store/modules/article.witness.module";
  export default {
    name: "index",
    components: { Error, Portlet, pagination },
    mixins: [witnessMixin, msgBoxConfirmMixin, commonMethodMixin],
    props: {
      id: {
        type: [Number, String]
      }
    },
    created() {
      this.autoLoadHomeData(1);
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE_WITNESS.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE"), route: {name: 'article.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t("PAGES.ARTICLE_WITNESS.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE")});
    },
    watch: {},
    computed: {
      ...mapGetters([
         'articleWitnesses', 'articleWitnessesMeta', 'articleWitnessesPage'
      ]),
      lang(){
        return this.$i18n.locale || 'ZH-UG';
      },
    },
    data(){
      return {
        loading: false,
        page: 1
      }
    },
    methods: {
      autoLoadHomeData(rootPage = null){
        this.loading = true;
        let {articleWitnessesPage} = this;
        if( !rootPage ){
          this.page = articleWitnessesPage
        }else if (rootPage) {
          this.page = rootPage
        }
        this.getArticleWitnesses(this.id, this.page)
          .catch(err=>{
            console.info('getArticleWitnesses.err: ', err);
          }).finally(()=>{
            this.loading = false;
          })
      },
      onChangePage(e){
        this.autoLoadHomeData(e.page);
      },
      onRefresh(){
        this.autoLoadHomeData(1);
      },
      onChangeSwitch(id, column, isShow){
        this.msgBoxConfirm({
          message: isShow ? this.$t("PAGES.ARTICLE_WITNESS.HOME.CONFIRM_TIPS.CLOSE"): this.$t("PAGES.ARTICLE_WITNESS.HOME.CONFIRM_TIPS.OPEN"),
          confirmCallback: confirm=>{
            if ( confirm ){
              this.switchColumn({
                resource: 'witnesses',
                id, column, mutationType: SET_ARTICLE_WITNESS_ERROR,
                begin: ()=>{ this.loading = true },
                after: ()=>{ this.loading = false },
              }).then((res)=>{
                let { audit } = res.data;
                let { articleWitnesses, articleWitnessesMeta } = this;
                let tmp = articleWitnesses.map(item=>{
                  if( item.id === id ){
                    item.audit = audit;
                  }
                  return item;
                });
                this.$store.commit(SET_ARTICLE_WITNESSES, { data: tmp, meta: articleWitnessesMeta});
              })
            }
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-container {
    .btn-group, .btn-group-vertical {
      display: flex !important;
    }
  }

</style>
