<template>
  <b-overlay :show="loading">
    <article-notification-form ref="form" :article_id="id" @loading="onLoading" @loaded="onLoaded" />
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import ArticleNotificationForm from "@v@/components/forms/ArticleNotification";
  export default {
    name: "index",
    components: {Error, ArticleNotificationForm},
    props: {
      id: {
        type: [Number, String]
      }
    },
    mixins: [],
    created() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'article.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.HOME_TITLE'), route: {name: 'article.notifications', params: {id: this.id}}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
    },
    mounted() {
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      onLoading(){
        this.loading = true;
      },
      onLoaded(e){
        this.loading = false;
      },
    }
  }
</script>

<style scoped>
.footer-actions {
  gap: 10px;
}
</style>
