<template>
  <b-overlay :show="loading">
    <article-notification-form ref="form" action="edit" :id="id" @loading="onLoading" @loaded="onLoaded" />
  </b-overlay>
</template>

<script>
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import Error from "@v@/partials/widgets/Error";
  import ArticleNotificationForm from "@v@/components/forms/ArticleNotification";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: {Error, ArticleNotificationForm},
    props: {
      id: {
        type: [Number, String]
      }
    },
    mixins: [],
    created() {
      this.setBreadCrumb();
    },
    mounted() {
    },
    computed: {
      ...mapGetters(['articleNotification']),
      articleId(){
        let { article_id = 0 } = this.articleNotification;
        return article_id
      },
    },
    watch: {
      articleId: {
        handler(v){
          if( v ){
            this.setBreadCrumb();
          }
        },
        immediate: true
      }
    },
    data() {
      return {
        loading: false,
      }
    },
    methods: {
      onLoading(){
        this.loading = true;
      },
      onLoaded(e){
        this.loading = false;
      },
      setBreadCrumb(){
        BreadCrumbs.setBreadCrumb([]);
        BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'article.home'}});
        BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.HOME_TITLE'), route: {name: 'article.notifications', params: {id: this.articleId}}});
        BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ARTICLE_NOTIFICATION.STORE.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
      }
    }
  }
</script>

<style scoped>
.footer-actions {
  gap: 10px;
}
</style>
